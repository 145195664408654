<template>
  <div>
    <nav-bar>
      <template v-slot:left>
        <div class="logo" @click="toIndex">
          <nav-bar-logo font="font-size: 26px;" />
        </div>
      </template>
      <template v-slot:right>
        <div class="navbar-r">
          <router-link to="/index">{{ $store.getters.getSiteInfo.name }}首页</router-link>
        </div>
      </template>
    </nav-bar>
    <div class="user-layout">
      <div class="register-body">
        <div class="title">
          <router-link to="/index">欢迎注册{{ $store.getters.getSiteInfo.name }}</router-link>
        </div>
        <!--  -->
        <sign-up-input @form-data="formData" @get-code="getCode" :numOrSms="false" :showRegisterPwd="true" :showRegisterWx="true" :showRegisterQq="true"/>
        <!-- 立即注册 -->
        <div class="btn-box">
          <el-button class="btn" @click="register()" :disabled="!(this.getFormData.accountBoole && this.getFormData.passwordBoole && this.getFormData.codeBoole)">立即注册</el-button>
        </div>
        <!-- 立即登录 -->
        <div class="gologin">已有账号...， <router-link to="/login">立即登录？</router-link></div>
        <br />
      </div>
      <RoutateCaptcha/>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar.vue'
import NavBarLogo from 'components/navbar/NavBarLogo.vue'
import SignUpInput from 'components/signUpInput/SignUpInput.vue'
import { register } from 'network/api'
import RoutateCaptcha from './yzm.vue'
export default {
  components: {
    NavBar,
    NavBarLogo,
    SignUpInput,
    RoutateCaptcha
  },
  data() {
    return {
      myurl: '',
      getFormData: {
        account: '',
        password: '',
        code: '',
        accountBoole: null,
        passwordBoole: null,
        wxBoole: null,
        qqBoole: null,
        codeBoole: null
      }
    }
  },

  methods: {
    toIndex() {
      this.$router.push('index')
    },
    // 监听子组件获取input值
    formData(value) {
      this.getFormData = value
    },
    // 监听子组件获取验证码
    getCode(value) {},
    // 注册
    register() {
      const key = localStorage.getItem('code')
      register(this.getFormData.account, this.getFormData.password, this.getFormData.wx, this.getFormData.qq, this.getFormData.code, key, this.$route.query.user_id).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg)
        }
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          const _this = this
          // 注册成功跳转到登录页
          setTimeout(function () {
            _this.$router.push('/login')
          }, 1500)
        }
      })
    }
  }
}
</script>

<style scoped src="styles/views/register/register.css">
</style>
